var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('CommonAlertDialog',{attrs:{"show":_vm.showAlert,"item":_vm.alertItem},on:{"onCloseAlert":_vm.onCloseAlert}}),_c('CustomerDeliverTypeRelationFilter',{on:{"onFilterChange":_vm.onFilterChange}}),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.createItem}},[_c('v-icon',[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm.$t("create"))+" ")],1)],1),_c('CustomerDeliverTypeRelationDialog',{attrs:{"show":_vm.showDialog,"action":_vm.action,"item":_vm.dialogItem,"deliver_suject_items":_vm.deliver_suject_items,"customer_items":_vm.customer_items},on:{"onClose":_vm.onCloseEvent,"onSave":_vm.onSaveEvent,"onSaveNext":_vm.onSaveNextEvent}}),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('customer.deliver-fee')}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":true,"show-current-page":true,"first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[20, 50, 100]},on:{"update:options":updateOptions}})]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_customer_name(item))+" ")]}},{key:"item.deliver_suject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_deliver_suject_name(item))+" ")]}},{key:"item.deliver_unit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("Translate")(_vm._f("getText")(item.deliver_unit,_vm.deliver_fee_unit_items)))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){_vm.deleteItem(item,_vm.$t('customer.deliver-fee'),item.customer.name_tc)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }