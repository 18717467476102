<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col>
              <CommonAutocomplete
                v-model="item.customer"
                :label="$t('customer.customer')"
                :placeholder="''"
                url="/customer/customer/"
                :item_text="nameField"
                item_value="id"
                :query_field="nameFieldFilter"
                :extra_filter="item"
                :filter_fields="{}"
                :cache="[item.customer]"
                :extra_condition="{
                  enable: true
                }"
                :required="true"
              ></CommonAutocomplete>
            </v-col>
            <v-col>
              <CommonAutocomplete
                v-model="item.deliver_suject"
                :label="$t('commodity.deliver-type')"
                :placeholder="''"
                url="/commodity/deliver-suject/"
                :item_text="nameField"
                item_value="id"
                :query_field="nameFieldFilter"
                :extra_filter="item"
                :filter_fields="{}"
                :cache="[item.deliver_suject]"
                :extra_condition="{
                  enable: true
                }"
                :required="true"
              ></CommonAutocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-select
                v-model="item.deliver_unit"
                :label="$t('commodity.unit')"
                :items="deliver_fee_unit_items"
                :item-text="item => $t(item.text)"
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="item.deliver_fee"
                :label="$t('commodity.deliver-fee')"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('commodity.deliver-fee') }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="disableSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
        <!-- <v-btn
          v-if="action == action_items.create"
          color="primary"
          :disabled="disableSave"
          @click="saveAndNext"
        >{{ $t("save and next") }}</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
import CommonAutocomplete from "../filters/CommonAutocomplete";
import { deliver_fee_unit_items } from "@/definition.js"
export default {
  props: {
    deliver_suject_items: [],
    customer_items: [],
  },
  data: (vm) => ({
    topic: vm.$i18n.t("customer.deliver-fee"),
    deliver_fee_unit_items: deliver_fee_unit_items,
  }),
  mixins: [BaseDialog],
  components: {
    CommonAutocomplete,
  },
  methods: {
    saveAndNext() {
      let editedItem = this._.cloneDeep(this.item);
      if (isNaN(editedItem.end_box)) {
        editedItem.end_box = editedItem.start_box;
      }
      // editedItem.commodity = editedItem.commodity.value; //using fk
      this.$emit("onSaveNext", editedItem, true);
    },
  },
};
</script>
