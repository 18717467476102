<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <CustomerDeliverTypeRelationFilter 
      v-on:onFilterChange="onFilterChange"
    ></CustomerDeliverTypeRelationFilter>
    <v-col class="text-right">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="createItem"
      >
        <v-icon>mdi-account-plus</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-col>
    <CustomerDeliverTypeRelationDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveEvent"
      v-on:onSaveNext="onSaveNextEvent"
      :deliver_suject_items="deliver_suject_items"
      :customer_items="customer_items"
    ></CustomerDeliverTypeRelationDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('customer.deliver-fee')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:item.customer="{ item }">
          {{ get_customer_name(item) }}
        </template>
        <template v-slot:item.deliver_suject="{ item }">
          {{ get_deliver_suject_name(item) }}
        </template>
        <template v-slot:item.deliver_unit="{ item }">
          {{ item.deliver_unit | getText(deliver_fee_unit_items) | Translate }}
        </template>
        <template v-slot:item.actions="{ item }">          
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="editItem(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="deleteItem(item,$t('customer.deliver-fee'),item.customer.name_tc)"
                >mdi-delete</v-icon
              >
            </template>
            <span>{{ $t("delete") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import CustomerDeliverTypeRelationDialog from "../dialogs/CustomerDeliverTypeRelationDialog";
import BaseTable from "./BaseTable.vue";
import { action_items, deliver_fee_unit_items } from "@/definition.js";
import CustomerDeliverTypeRelationFilter from "../filters/CustomerDeliverTypeRelationFilter"
export default {
  data: (vm) => ({
    url: "/commodity/customer-deliver-type-relation/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
        width: "5%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("customer.name"),
        value: "customer",
        width: "10%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.deliver-type"),
        value: "deliver_suject",
        width: "10%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.unit"),
        value: "deliver_unit",
        width: "10%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.deliver-fee"),
        value: "deliver_fee",
        width: "10%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
        width: "5%"
      }
    ],
    deliver_suject_items: [],
    customer_items: [],
    action_items: action_items,
    deliver_fee_unit_items: deliver_fee_unit_items,
  }),
  mixins: [BaseTable],
  components: {
    CustomerDeliverTypeRelationDialog,
    CustomerDeliverTypeRelationFilter,
    CommonAlertDialog
  },
  methods: {
    createItem(item) {
      this.dialogItem = this._.cloneDeep(this.defaultItem);
      this.action = this.action_items.create;
      this.showDialog = true;
    },
    editItem(item){
      this.orig_dialogItem = this._.cloneDeep(item);
      this.dialogItem = this._.cloneDeep(item);
      this.action = action_items.edit;
      this.showDialog = true;
    },
    get_customer_name(item){
      return this.getHanText(item.customer,"name")
    },
    get_deliver_suject_name(item){
      return this.getHanText(item.deliver_suject,"name")
    },
    onSaveNextEvent(data) {
      this.onSaveEvent(data);
      this.defaultItem = {
        customer: data.customer,
      };
      this.createItem({});
    },
  },
};
</script>
