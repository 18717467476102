<template>
  <v-container>
    <v-row>
      <v-col>
        <CommonAutocomplete
          v-model="filterItem.customer"
          :label="$t('customer.customer')"
          url="/customer/customer/"
          :item_text="nameField"
          item_value="id"
          :query_field="nameFieldFilter"
          :extra_filter="filterItem"
          :extra_condition="{
            enable: true
          }"
          @change="onChange"
        ></CommonAutocomplete>
      </v-col>
      <v-col>
        <CommonAutocomplete
          v-model="filterItem.deliver_suject"
          :label="$t('commodity.deliver-type')"
          url="/commodity/deliver-suject/"
          :item_text="nameField"
          item_value="id"
          :query_field="nameFieldFilter"
          :extra_filter="filterItem"
          :extra_condition="{
            enable: true
          }"
          @change="onChange"
        ></CommonAutocomplete>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="onChange"
        >
          <v-icon left>mdi-magnify</v-icon>
          {{ $t("search") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  
</template>
<script>
import BaseFilter from "./BaseFilter";
import CommonAutocomplete from "./CommonAutocomplete";
export default {
  data: (vm) => ({}),
  mixins: [BaseFilter],
  components: {
    CommonAutocomplete,
  },
  methods: {},
};
</script>